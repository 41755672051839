import { ContactForm, ContactFormOptions } from "@app/helpers/ContactForm";
import { HamburgerMenu } from "@app/helpers/HamburgerMenu";
import Cleave from "cleave.js";
import gsap from "gsap";
import MicroModal from "micromodal";
import $ from 'jquery';
import { env } from "@app/env";

const RapidMobileactiveClass = 'rapid-mobile-visible';
const RapidBackdropMobileactiveClass = 'mobile-rapid-backdrop-active';

function hideRapidMenu() {
    const rapid = document.querySelector<HTMLButtonElement>('.rapid');
    const backdrop = document.querySelector<HTMLButtonElement>('.mobile-rapid-backdrop');
    if (rapid && backdrop) {
        rapid.classList.remove(RapidMobileactiveClass);
        backdrop.classList.remove(RapidBackdropMobileactiveClass);
    }
}

export function HeaderMenuActive() {
    const menuLinks = document.querySelectorAll<HTMLAnchorElement>('.menu-link');
    if (menuLinks.length < 1) {
        return;
    }

    function getCoords(element: HTMLElement) {
        const box = element.getBoundingClientRect();

        const body = document.body;
        const docEl = document.documentElement;

        const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        const clientTop = docEl.clientTop || body.clientTop || 0;
        const clientLeft = docEl.clientLeft || body.clientLeft || 0;

        const top  = box.top +  scrollTop - clientTop;
        const left = box.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left) };
    }

    const menuLinkActiveClass = 'menu-link-active';
    const secondSection = document.getElementById('servicos') as HTMLDivElement;
    function applyActiveMenuLink() {
        for (let i = 0; i < menuLinks.length; i++) {
            const link = menuLinks[i];
            const id = link.href.split('#')[1];

            link.classList.remove(menuLinkActiveClass);
            if (id.length > 0) {
                const section = document.getElementById(id) as HTMLDivElement;
                if (!section) {
                    return;
                }
                const clientRect = getCoords(section);
                const offset = 160;

                if ((clientRect.top - offset) <= document.documentElement.scrollTop && (clientRect.top + section.offsetHeight - offset) > document.documentElement.scrollTop) {
                    link.classList.add(menuLinkActiveClass);
                }
            } else {
                if (!secondSection) {
                    return;
                }
                const secondSectionClientRect = getCoords(secondSection);

                if (document.documentElement.scrollTop < secondSectionClientRect.top) {
                    menuLinks[0].classList.add(menuLinkActiveClass);
                }
            }
        }
    }

    document.addEventListener('scroll', applyActiveMenuLink);
    applyActiveMenuLink();
}

export function GoToLinks() {
    const goToLink = document.querySelectorAll<HTMLAnchorElement>('.link-go-to');
    if (goToLink.length < 1) {
        return;
    }

    function triggerScroll(this: HTMLAnchorElement, e: MouseEvent) {
        e.preventDefault();
        gsap.to(window, {
            duration: 1,
            scrollTo: {
                y: document.getElementById(this.href.split('#')[1]) || document.body,
                offsetY: parseInt(this.dataset.offsetY || '0'),
            },
            ease: 'expo',
        });
        hideRapidMenu();
    }

    goToLink.forEach((link: HTMLAnchorElement) => {
        link.addEventListener('click', triggerScroll);
    });
}

export function MobileMenu() {
    const button = document.getElementById('menu-hamburger-button') as HTMLDivElement;
    const menu = document.querySelector<HTMLDivElement>('.menu');
    const menuLinks = document.querySelectorAll<HTMLAnchorElement>('.menu-link');
    if (!button || !menu || menuLinks.length < 1) {
        return;
    }

    const hambMenu = new HamburgerMenu({
        button,
        menu
    });
    menuLinks.forEach((link: HTMLAnchorElement) => {
        link.addEventListener('click', hambMenu.close);
    });
}

export function ModalTriggers() {
    const triggers = document.querySelectorAll<HTMLElement>('.has-modal-action');
    if (triggers.length < 1) {
        return;
    }

    function onOpenModal(this: HTMLElement) {
        if(this.dataset.modalId && document.getElementById(this.dataset.modalId)){
            hideRapidMenu();
            MicroModal.show(this.dataset.modalId);
        }
    }

    for (let i = 0; i < triggers.length; i++) {
        triggers[i].addEventListener('click', onOpenModal);
    }
}

export function ModalQuerystringTriggers() {
    const triggers = document.querySelectorAll<HTMLDivElement>('[querystring-trigger]');

    if (triggers.length < 1) {
        return;
    }

    const URLSP = new URLSearchParams(window.location.search);
    for (let i = 0; i < triggers.length; i++) {
        const trigger = triggers[i].getAttribute('querystring-trigger');
        const id = triggers[i].getAttribute('id');
        if (!trigger || !id) {
            break;
        }

        if (URLSP.get(trigger) === 'visualizar') {
            MicroModal.show(id);
            break;
        }
    }
}

export function MobileRapidTrigger() {
    const trigger = document.querySelector<HTMLButtonElement>('.header-rapid-trigger');
    const rapid = document.querySelector<HTMLButtonElement>('.rapid');
    const backdrop = document.querySelector<HTMLDivElement>('.mobile-rapid-backdrop');
    if (!trigger || !rapid || !backdrop) {
        return;
    }


    function onToggle() {
        if (rapid?.classList.contains(RapidMobileactiveClass)) {
            rapid?.classList.remove(RapidMobileactiveClass);
            backdrop?.classList.remove(RapidBackdropMobileactiveClass);
        } else {
            rapid?.classList.add(RapidMobileactiveClass);
            backdrop?.classList.add(RapidBackdropMobileactiveClass);
        }
    }
    trigger.addEventListener('click', onToggle);
    backdrop.addEventListener('click', (e) => {
        e.stopPropagation();
        hideRapidMenu();
    });
}

function applyDateMasks(elements: NodeListOf<Element>) {
    elements.forEach((el: any) => {
        new Cleave(el, {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
            // swapHiddenInput: true,
            // onValueChanged: function (this: any, e: any) {
            //     this.elementSwapHidden.value = e.target.value.replace(/\D/g, '')
            //         .replace(/(\d{2})(\d{2})(\d{4})/, '$3-$2-$1');
            // }
        });
    });
}

function applyCpfMasks(elements: NodeListOf<Element>) {
    elements.forEach((el: any) => {
        new Cleave(el, {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true,
        });
    });
}

function applyPhoneMasks(elements: NodeListOf<Element>) {
    elements.forEach((el: any) => {
        new Cleave(el, {
            numericOnly: true,
            blocks: [0, 2, 0, 5, 4],
            delimiters: ['(',')', ' ', '-'],
        });
    });
}

export function InputMasks() {
    applyDateMasks(document.querySelectorAll('.date-mask'));
    applyPhoneMasks(document.querySelectorAll('.phone-mask'));
    applyCpfMasks(document.querySelectorAll('.cpf-mask'));
    document.querySelectorAll('.cnpj-mask').forEach((el: any) => {
        new Cleave(el, {
            delimiters: ['.', '.', '/', '-'],
            blocks: [2, 3, 3, 4, 2],
            numericOnly: true,
        });
    });
    document.querySelectorAll('.cep-mask').forEach((el: any) => {
        new Cleave(el, {
            delimiters: ['.', '-'],
            blocks: [2, 3, 3],
            numericOnly: true,
        });
    });
};

export function Forms() {
    function handleUnvalidated(res: any, config: ContactFormOptions) {
        const output = [
            '<div><strong>Por favor, verifique todos os campos abaixo:</strong></div>',
            '<ul class="feedback-ul">',
        ];
        for (const field in res.error_data) {
            output.push(`<li class="feedback-li">${res.error_data[field]}</li>`)
        }
        output.push(`</ul>`);
        return output.join('');
    }

    const paymentForm = document.querySelector<HTMLFormElement>('.payment-form');
    const paymentFormFeedback = document.querySelector<HTMLDivElement>('.payment-form-feedback');
    const paymentFormSubmit = document.querySelector<HTMLButtonElement>('.payment-form-submit');
    if (paymentForm && paymentFormFeedback && paymentFormSubmit) {
        new ContactForm({
            form: paymentForm,
            feedback: paymentFormFeedback,
            submitButton: paymentFormSubmit,
            onUnvalidated: handleUnvalidated,
        });
    }

    const wwuForm = document.querySelector<HTMLFormElement>('.wwu-form');
    const wwuFormFeedback = document.querySelector<HTMLDivElement>('.wwu-form-feedback');
    const wwuFormSubmit = document.querySelector<HTMLButtonElement>('.wwu-form-submit');
    if (wwuForm && wwuFormFeedback && wwuFormSubmit) {
        new ContactForm({
            form: wwuForm,
            feedback: wwuFormFeedback,
            submitButton: wwuFormSubmit,
            onUnvalidated: handleUnvalidated,
        });
    }

    const contactForm = document.querySelector<HTMLFormElement>('.contact-form');
    const contactFormFeedback = document.querySelector<HTMLDivElement>('.contact-form-feedback');
    const contactFormSubmit = document.querySelector<HTMLButtonElement>('.contact-form-submit');
    if (contactForm && contactFormFeedback && contactFormSubmit) {
        new ContactForm({
            form: contactForm,
            feedback: contactFormFeedback,
            submitButton: contactFormSubmit,
            onUnvalidated: handleUnvalidated
        });
    }

    const budgetForm = document.querySelector<HTMLFormElement>('.budget-form');
    const budgetFormFeedback = document.querySelector<HTMLDivElement>('.budget-form-feedback');
    const budgetFormSubmit = document.querySelector<HTMLButtonElement>('.budget-form-submit');
    if (budgetForm && budgetFormFeedback && budgetFormSubmit) {
        new ContactForm({
            form: budgetForm,
            feedback: budgetFormFeedback,
            submitButton: budgetFormSubmit,
            onUnvalidated: handleUnvalidated,
            onSuccess: function () {
                // @ts-ignore
                if (window.dataLayer) {
                    // @ts-ignore
                    window.dataLayer.push({'event':'budget-form-success'});
                }
            }
        });
    }

    const boletoForm = document.querySelector<HTMLFormElement>('.boleto-form');
    const boletoFormFeedback = document.querySelector<HTMLDivElement>('.boleto-form-feedback');
    const boletoFormSubmit = document.querySelector<HTMLButtonElement>('.boleto-form-submit');
    if (boletoForm && boletoFormFeedback && boletoFormSubmit) {
        boletoForm.addEventListener('submit', function (this: HTMLFormElement, e: Event) {
            e.preventDefault();
            $.ajax({
                url: boletoForm.action,
                method: boletoForm.method,
                data: $(this).serialize(),
                beforeSend: function () {
                    boletoFormSubmit.disabled = true;
                },
                success: function (res) {
                    const output = [
                        '<div class="form-feedback-message form-feedback-message-success">',
                            res.msg,
                        '</div>'
                    ];
                    boletoFormFeedback.innerHTML = output.join('');
                },
                error: function (err) {
                    const output = [
                        '<div class="form-feedback-message form-feedback-message-error">',
                            err.responseJSON.msg,
                        '</div>'
                    ];
                    boletoFormFeedback.innerHTML = output.join('');
                },
                complete: function () {
                    boletoFormSubmit.disabled = false;
                }
            });
        });
    }


    function getChangeDependentFormLiMarkup() {
        const output = [
            '<div class="form-field-group change-form-field-group-remove">',
                '<div class="form-field">',
                    '<label for="change-form-input-dependents_name_', changeFormDependentsUlChildrenIndex, '" class="form-label">Nome</label>',
                    '<input type="text" name="dependents[', changeFormDependentsUlChildrenIndex, '][name]" class="form-input form-input-text" id="change-form-input-dependents_name_', changeFormDependentsUlChildrenIndex, '" />',
                '</div>',
                '<button type="button" class="change-form-dependents-remove-button">',
                    '<img class="img-responsive" src="', env.imgurl, '/icon-trash.svg" width="20" height="23" role="presentation" alt /></span>',
                '</button>',
            '</div>',
            '<div class="form-field-group">',
                '<div class="form-field">',
                    '<label for="change-form-input-dependents_cpf_', changeFormDependentsUlChildrenIndex, '" class="form-label">CPF</label>',
                    '<input type="text" name="dependents[', changeFormDependentsUlChildrenIndex, '][cpf]" class="form-input form-input-text cpf-mask" id="change-form-input-dependents_cpf_', changeFormDependentsUlChildrenIndex, '" />',
                '</div>',
                '<div class="form-field">',
                    '<label for="change-form-input-dependents_birthdate_', changeFormDependentsUlChildrenIndex, '" class="form-label">Data de nascimento</label>',
                    '<input type="text" name="dependents[', changeFormDependentsUlChildrenIndex, '][birthdate]" class="form-input form-input-text date-mask" id="change-form-input-dependents_birthdate_', changeFormDependentsUlChildrenIndex, '" />',
                '</div>',
            '</div>',
            '<div class="stack-h mb-20p cgap-10p">',
                '<span class="form-label mb-0">Sexo:</span>',
                '<div class="radio">',
                    '<input type="radio" name="dependents[', changeFormDependentsUlChildrenIndex, '][sex]" class="radio-input" value="Masculino" id="change-form-input-radio-sex-masculino_', changeFormDependentsUlChildrenIndex, '"/>',
                    '<label for="change-form-input-radio-sex-masculino_', changeFormDependentsUlChildrenIndex, '" class="radio-label">Masculino</label>',
                '</div>',
                '<div class="radio">',
                    '<input type="radio" name="dependents[', changeFormDependentsUlChildrenIndex, '][sex]" class="radio-input" value="Feminino" id="change-form-input-radio-sex-feminino_', changeFormDependentsUlChildrenIndex, '"/>',
                    '<label for="change-form-input-radio-sex-feminino_', changeFormDependentsUlChildrenIndex, '" class="radio-label">Feminino</label>',
                '</div>',
                '<div class="radio">',
                    '<input type="radio" name="dependents[', changeFormDependentsUlChildrenIndex, '][sex]" class="radio-input" value="Não especificado" id="change-form-input-radio-sex-nao-especificado_', changeFormDependentsUlChildrenIndex, '"/>',
                    '<label for="change-form-input-radio-sex-nao-especificado_', changeFormDependentsUlChildrenIndex, '" class="radio-label">Não especificado</label>',
                '</div>',
            '</div>',
        ];
        changeFormDependentsUlChildrenIndex += 1;
        return output.join('');
    }

    function onAddChangeDependentLi() {
        const li = document.createElement('li');
        li.classList.add('change-form-dependents-li');
        li.innerHTML = getChangeDependentFormLiMarkup();
        li.querySelector('.change-form-dependents-remove-button')?.addEventListener('click', onRemoveChangeDependentLi);
        applyCpfMasks(li.querySelectorAll('.cpf-mask'));
        applyDateMasks(li.querySelectorAll('.date-mask'));
        applyPhoneMasks(li.querySelectorAll('.phone-mask'));
        changeFormDependentsUl?.appendChild(li);
    }

    function onRemoveChangeDependentLi(this: HTMLButtonElement) {
        const liToRemove = this.parentElement?.parentElement;
        liToRemove?.parentElement?.removeChild(liToRemove);
    }

    function checkChangeAuthorizationFileVisibility() {
        if (changeFormInputType?.value === 'Inquilino' && changeFormInputObjective?.value === 'Entrada') {
            changeFormFieldAuthorizationFile?.classList.remove('d-none');
        } else {
            changeFormFieldAuthorizationFile?.classList.add('d-none');
            // @ts-ignore
            changeFormAuthorizationFileInput?.value = '';
        }
    }

    function onChangeFormInputType(this: HTMLSelectElement) {
        if (changeFormAuthorizationFileText) {
            changeFormAuthorizationFileText.innerHTML = this.options[this.selectedIndex].dataset.text || '';
        }
        checkChangeAuthorizationFileVisibility();
    }

    function onChangeFormRadioInputsDocType(this: HTMLInputElement) {
        changeFormConditionalFields.forEach((div) => {
            div.classList.add('d-none');
        });
        changeForm?.querySelectorAll(`.${this.dataset.visibleClass}`).forEach((el) => {
            el.classList.remove('d-none');
        });
    }

    const changeForm = document.querySelector<HTMLFormElement>('.change-form');
    const changeFormFeedback = document.querySelector<HTMLDivElement>('.change-form-feedback');
    const changeFormSubmit = document.querySelector<HTMLButtonElement>('.change-form-submit');
    const changeFormAddDependentButton = document.querySelector<HTMLButtonElement>('.change-form-dependents-add-button');
    const changeFormDependentsUl = document.querySelector<HTMLUListElement>('.change-form-dependents-ul');
    const changeFormInputType = document.querySelector<HTMLSelectElement>('#change-form-input-type');
    const changeFormInputObjective = document.querySelector<HTMLSelectElement>('#change-form-input-objective');
    const changeFormAuthorizationFileText = document.querySelector<HTMLSpanElement>('.change-form-input-authorization_file_text');
    const changeFormRadioInputsDocType = document.querySelectorAll<HTMLInputElement>('.change-radio-input-doc_type');
    const changeFormConditionalFields = document.querySelectorAll<HTMLDivElement>('.form-conditional-field');
    const changeFormFieldAuthorizationFile = document.querySelector<HTMLDivElement>('.form-field-authorization_file');
    const changeFormAuthorizationFileInput = document.querySelector<HTMLInputElement>('#change-form-input-authorization_file');
    let changeFormDependentsUlChildrenIndex: number = 0;
    if (
        changeForm
        && changeFormFeedback
        && changeFormSubmit
        && changeFormAddDependentButton
        && changeFormDependentsUl
        && changeFormInputType
        && changeFormFieldAuthorizationFile
        && changeFormInputObjective
        && changeFormAuthorizationFileInput
    ) {
        new ContactForm({
            form: changeForm,
            feedback: changeFormFeedback,
            submitButton: changeFormSubmit,
            onUnvalidated: handleUnvalidated
        });
        changeFormAddDependentButton.addEventListener('click', onAddChangeDependentLi);
        changeFormInputType.addEventListener('change', onChangeFormInputType)
        changeFormInputObjective.addEventListener('change', checkChangeAuthorizationFileVisibility)
        changeFormRadioInputsDocType.forEach((radio: HTMLInputElement) => {
            radio.addEventListener('change', onChangeFormRadioInputsDocType);
        })
    }
}
