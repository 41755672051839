import $ from 'jquery';

export function HomeBanners() {
    const bannersW = document.querySelector<HTMLDivElement>('.home-banners');
    const dotsW = document.querySelector<HTMLDivElement>('.home-banners-dots');
    if (!bannersW || bannersW.children.length < 1 || !dotsW) {
        return;
    }

    $(bannersW).slick({
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        dotsClass: 'dots-list',
        appendDots: dotsW,
        customPaging: function slickCustomPaging() {
            return '<button type="button" class="dots-list-button"></button>';
        },
        responsive: [
            // {
            //     breakpoint: 768,
            //     settings: {
            //         dots: false,
            //     }
            // },
        ]
    });
}

export function HomeSysBanners() {
    const featureTextsW = document.querySelector<HTMLDivElement>('.home-sys-features');
    const featureImagesW = document.querySelector<HTMLDivElement>('.home-sys-feature-images');
    const dotsW = document.querySelector<HTMLDivElement>('.home-sys-feature-dots-wrapper');
    if (!featureTextsW || !featureImagesW || !dotsW) {
        return;
    }

    $(featureTextsW).slick({
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        dotsClass: 'dots-list',
        appendDots: dotsW,
        asNavFor: featureImagesW,
        customPaging: function slickCustomPaging() {
            return '<button type="button" class="dots-list-button"></button>';
        },
        // responsive: [
        //     {
        //         breakpoint: 768,
        //         settings: {
        //             adaptiveHeight: true
        //         }
        //     },
        // ]
    });

    $(featureImagesW).slick({
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        asNavFor: featureTextsW,
        fade: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    adaptiveHeight: true
                }
            },
        ]
    });
}

export function HomePillars() {
    const pillarsW = document.querySelector<HTMLDivElement>('.home-pillars');
    const dotsW = document.querySelector<HTMLDivElement>('.home-pillars-dots-wrapper');
    if (!pillarsW || !dotsW) {
        return;
    }

    $(pillarsW).slick({
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        dotsClass: 'dots-list',
        appendDots: dotsW,
        customPaging: function slickCustomPaging() {
            return '<button type="button" class="dots-list-button"></button>';
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            },
        ]
    });
}
