
export type HamburgerMenuConfig = {
    button: HTMLElement,
    menu: HTMLElement,
    isOpen?: boolean,
    buttonOpenClass?: string,
    menuOpenClass?: string,
    onChange?: (isVisible: boolean, config: HamburgerMenuConfig) => void,
};

export class HamburgerMenu {
    config: any = {
        isOpen: false,
        buttonOpenClass: 'is-active',
        menuOpenClass: 'menu-is-visible',
    };

    constructor(config: HamburgerMenuConfig) {
        this.config = {
            ...this.config,
            ...config
        };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        // Assignments
        this.config.button.addEventListener('click', this.toggleMenu);
    }

    toggleMenu() : void {
        if (this.config.isOpen) {
            this.close();
        } else {
            this.open();
        }
        if (typeof this.config.onChange === 'function') {
            this.config.onChange(this.config.isOpen, this.config);
        }
    }

    open() : void {
        this.config.isOpen = true;
        this.config.button.classList.add(this.config.buttonOpenClass);
        this.config.menu.classList.add(this.config.menuOpenClass);
    }

    close() : void {
        this.config.isOpen = false;
        this.config.button.classList.remove(this.config.buttonOpenClass);
        this.config.menu.classList.remove(this.config.menuOpenClass);
        if (typeof this.config.onClose === 'function') {
            this.config.onClose(this.config.isOpen);
        }
    }
}