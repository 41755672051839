export type ContactFormOptions = {
    form: HTMLFormElement,
    feedback: HTMLElement,
    submitButton: HTMLButtonElement,
    feedbackWrapperClass?: string,
    feedbackSuccessClass?: string,
    feedbackErrorClass?: string,
    feedbackSuccessMessage?: string,
    feedbackUnvalidatedClass?: string,
    feedbackUnvalidatedMessage?: string,
    feedbackErrorMessage?: string,
    loadingMessage?: string,
    action?: string,
    method?: string,
    preventFormClearOnSuccess?: boolean,
    beforeSubmit?: (data: FormData, config: ContactFormOptions) => FormData,
    onDone?: (e: ProgressEvent, config: ContactFormOptions) => void,
    onSuccess?: (res: any, config: ContactFormOptions) =>  void | string,
    onError?: (res: any, config: ContactFormOptions) =>  void | string,
    onUnvalidated?: (res: any, config: ContactFormOptions) => void | string,
    onProgress?: (percentage: number, e: ProgressEvent, config: ContactFormOptions) => void,
};

export class ContactForm {
    config: ContactFormOptions;
    xhr: XMLHttpRequest;

    constructor(config: ContactFormOptions) {
        this.config = {
            feedbackWrapperClass: 'form-feedback-message',
            feedbackSuccessClass: 'form-feedback-message-success',
            feedbackErrorClass: 'form-feedback-message-error',
            feedbackSuccessMessage: 'Mensagem enviada com sucesso.',
            feedbackUnvalidatedClass: 'form-feedback-message-unvalidated',
            feedbackUnvalidatedMessage: 'Ops, parece que alguns campos estão incompletos.',
            feedbackErrorMessage: 'Ops, tivemos um problema para enviar sua mensagem.',
            loadingMessage: 'Enviando...',
            preventFormClearOnSuccess: false,
            ...config
        };
        this.xhr = new XMLHttpRequest();

        this.onSubmit = this.onSubmit.bind(this);
        this.onXhrProgress = this.onXhrProgress.bind(this);
        this.onXhrDone = this.onXhrDone.bind(this);
        this.onXhrReadyStateChange = this.onXhrReadyStateChange.bind(this);
        this.onSuccessResponse = this.onSuccessResponse.bind(this);
        this.onUnvalidatedResponse = this.onUnvalidatedResponse.bind(this);
        this.onErrorResponse = this.onErrorResponse.bind(this);

        if (!this.config.action) {
            this.config.action = this.config.form.action || (window.location.origin + window.location.pathname);
        }
        if (!this.config.method) {
            this.config.method = this.config.form.method || 'post';
        }

        this.xhr.onreadystatechange = this.onXhrReadyStateChange;
        if (typeof this.config.onProgress === 'function') {
            this.xhr.upload.onprogress = this.onXhrProgress;
        }

        if (this.xhr.upload) {
            this.xhr.upload.onload = this.onXhrDone;
        } else {
            this.xhr.onload = this.onXhrDone;
        }

        // @ts-ignore
        this.config.form.addEventListener('submit', this.onSubmit);
    }

    onXhrReadyStateChange() {
        if (this.xhr.readyState === 4) {
            if (this.config.submitButton) {
                this.config.submitButton.disabled = false;
            }
            const res = JSON.parse(this.xhr.responseText);
            switch (this.xhr.status) {
                case 200:
                case 201:
                    this.onSuccessResponse(res);
                    break;
                case 422:
                    this.onUnvalidatedResponse(res);
                    break;
                default:
                    this.onErrorResponse(res);
                    break;
            }
        }
    }

    onXhrProgress(e: ProgressEvent) {
        if (e.lengthComputable) {
            // @ts-ignore
            this.config.onProgress(Math.floor((e.loaded / e.total) * 100), e, this.config);
        }
    }

    onXhrDone(e: ProgressEvent) {
        if (typeof this.config.onDone === 'function') {
            this.config.onDone(e, this.config);
        }
    }

    onSuccessResponse(res: any) {
        let message;
        if (typeof this.config.onSuccess === 'function') {
            message = this.config.onSuccess(res, this.config);
        }
        
        const output = [
            '<div class="', this.config.feedbackWrapperClass, '">',
                message || this.config.feedbackSuccessMessage,
            '</div>'
        ];
        this.config.feedback.innerHTML = output.join('');
        if (this.config.preventFormClearOnSuccess !== true) {
            this.config.form.reset();
        }
        window.setTimeout(() => {
            this.config.feedback.children[0].className += ' ' + this.config.feedbackSuccessClass;
        }, 100);
    }

    onUnvalidatedResponse(res: any) {
        let message;
        if (typeof this.config.onUnvalidated === 'function') {
            message = this.config.onUnvalidated(res, this.config);
        }

        const output = [
            '<div class="', this.config.feedbackWrapperClass, '">',
                message || this.config.feedbackUnvalidatedMessage,
            '</div>'
        ];
        this.config.feedback.innerHTML = output.join('');
        window.setTimeout(() => {
            this.config.feedback.children[0].className += ' ' + this.config.feedbackUnvalidatedClass;
        }, 100);
    }

    onErrorResponse(res: any) {
        let message;
        if (typeof this.config.onError === 'function') {
            message = this.config.onError(res, this.config);
        }

        const output = [
            '<div class="', this.config.feedbackWrapperClass, '">',
                message || this.config.feedbackErrorMessage,
            '</div>'
        ];
        this.config.feedback.innerHTML = output.join('');
        window.setTimeout(() => {
            this.config.feedback.children[0].className += ' ' + this.config.feedbackErrorClass;
        }, 100);
    }

    onSubmit(e: SubmitEvent) {
        e.preventDefault();
        this.config.feedback.innerHTML = this.config.loadingMessage || '';
        let data = new FormData(this.config.form);
        if (typeof this.config.beforeSubmit === 'function') {
            data = this.config.beforeSubmit(data, this.config);
        }
        if (this.config.submitButton) {
            this.config.submitButton.disabled = true;
        }

        // @ts-ignore
        this.xhr.open(this.config.method, this.config.action, true);
        this.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        this.xhr.send(data);
    }
}
