import $ from 'jquery';

export function Minibanners() {
    const bannersW = document.querySelector<HTMLDivElement>('.budget-banner-minibanners');
    const dotsW = document.querySelector<HTMLDivElement>('.budget-minibanners-dots-wrapper');
    if (!bannersW || bannersW.children.length < 1 || !dotsW) {
        return;
    }
    
    $(bannersW).slick({
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        dotsClass: 'dots-list',
        appendDots: dotsW,
        mobileFirst: true,
        customPaging: function slickCustomPaging() {
            return '<button type="button" class="dots-list-button"></button>';
        },
        responsive: [{
            breakpoint: 768,
            settings: 'unslick'
        }]
    });
}

export function PointsMobileSlider() {
    const bannersW = document.querySelector<HTMLDivElement>('.budget-points-ul');
    const dotsW = document.querySelector<HTMLDivElement>('.budget-points-dots-wrapper');
    if (!bannersW || bannersW.children.length < 1 || !dotsW) {
        return;
    }
    
    $(bannersW).slick({
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        dotsClass: 'dots-list',
        appendDots: dotsW,
        mobileFirst: true,
        adaptiveHeight: true,
        customPaging: function slickCustomPaging() {
            return '<button type="button" class="dots-list-button"></button>';
        },
        responsive: [{
            breakpoint: 576,
            settings: 'unslick'
        }]
    });
}
