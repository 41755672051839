import './../styles/app.scss';
import './vendor/modernizr';
import 'lazysizes';
import 'slick-carousel';
import a11yChecker from 'a11y-checker';
import { env } from '@app/env';
import { HomeBanners, HomePillars, HomeSysBanners } from '@app/pages/home';
import { Forms, GoToLinks, HeaderMenuActive, InputMasks, MobileMenu, MobileRapidTrigger, ModalQuerystringTriggers, ModalTriggers } from './pages/common';
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Minibanners, PointsMobileSlider } from './pages/budget';

gsap.registerPlugin(ScrollToPlugin);

if (env.env !== 'production') {
    a11yChecker();
}
(function () {

HeaderMenuActive();
GoToLinks();
MobileMenu();
ModalTriggers();
Forms();
MobileRapidTrigger();
InputMasks();
switch (document.body.id) {
    case 'body-home':
        HomeBanners();
        HomeSysBanners();
        HomePillars();
        break;
    case 'body-budget':
        Minibanners();
        PointsMobileSlider();
        break;
    default:
        break;
}

ModalQuerystringTriggers();

})();
